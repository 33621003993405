/**
 * @generated SignedSource<<dcfa4962f8992999c281009cbe9b3ffc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoContentHero_data$data = {
  readonly ctaButtonText?: string | null;
  readonly focusContent?: {
    readonly customPromotionTitle?: string | null;
    readonly fallbackImage?: {
      readonly url: string;
    } | null;
    readonly gameTags?: ReadonlyArray<string>;
    readonly jackpot?: {
      readonly amount: number;
    } | null;
    readonly launchUrl?: string;
    readonly name?: string;
    readonly programExternalId?: string;
    readonly promotionId?: string;
    readonly promotionSlug?: string | null;
    readonly slug?: string;
  } | null;
  readonly sellingText?: {
    readonly jsonContent: {
      readonly json: string | null;
    };
  } | null;
  readonly video: {
    readonly desktopUrl: string | null;
    readonly mobileUrl: string | null;
  } | null;
  readonly " $fragmentType": "VideoContentHero_data";
};
export type VideoContentHero_data$key = {
  readonly " $data"?: VideoContentHero_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"VideoContentHero_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VideoContentHero_data",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "focusContent",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "launchUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "gameTags",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Jackpot",
                  "kind": "LinkedField",
                  "name": "jackpot",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Image",
                  "kind": "LinkedField",
                  "name": "fallbackImage",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "FocusContentHeroGame",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v0/*: any*/),
              "type": "FocusContentHeroPage",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "programExternalId",
                  "storageKey": null
                }
              ],
              "type": "FocusContentHeroBonus",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "promotionId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "promotionSlug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customPromotionTitle",
                  "storageKey": null
                }
              ],
              "type": "FocusContentHeroPromotion",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v0/*: any*/),
              "type": "FocusContentHeroTag",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaButtonText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroRichTextBlock",
          "kind": "LinkedField",
          "name": "sellingText",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Json",
              "kind": "LinkedField",
              "name": "jsonContent",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "json",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "FocusContentHeroFields",
      "abstractKey": "__isFocusContentHeroFields"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoContentHeroVideo",
      "kind": "LinkedField",
      "name": "video",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "desktopUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mobileUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "VideoContentHero",
  "abstractKey": null
};
})();

(node as any).hash = "ae92d353ca8b5f0db2ccce819c64cafc";

export default node;
