import type { FC, ReactNode } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { useRouter } from 'next/router';
import { Video } from '@pafcloud/base-components';
import { Breakpoint } from '@pafcloud/style';
import { useIsSSR, useMedia } from '@pafcloud/react-hook-utils';
import { useFlowRouter } from '@pafcloud/flow-router';
import { FlexibleGameListThumbnail } from '@pafcloud/game-components';
import type {
  VideoContentHero_data$data,
  VideoContentHero_data$key,
} from './__generated__/VideoContentHero_data.graphql';
import { HeroContentWrapper } from './hero-content-blocks/HeroContentWrapper';
import { HeroRichTextComponent } from './hero-content-blocks/HeroRichText';
import { HeroCtaButtonLink } from './hero-content-blocks/hero-button/HeroCtaButtonStyle';
import { Content, ContentSection, GameTag, GameTags, PictureLink, prettifyHeroTagName } from './focusContentHero';

const dataFragment = graphql`
  fragment VideoContentHero_data on VideoContentHero {
    ...focusContentHero_content @relay(mask: false)
    video {
      desktopUrl
      mobileUrl
    }
  }
`;

type PictureMaterialProps = {
  fallbackImage: NonNullable<VideoContentHero_data$data['focusContent']>['fallbackImage'];
  gameName: NonNullable<VideoContentHero_data$data['focusContent']>['name'];
  loading?: 'eager' | 'lazy';
  video: VideoContentHero_data$data['video'];
};

const VideoContentHeroPictureMaterial: FC<PictureMaterialProps> = ({ video, fallbackImage, gameName, loading }) => {
  const isSSR = useIsSSR();
  const isPhone = useMedia(Breakpoint.Phone);
  const noMotion = useMedia('(prefers-reduced-motion: reduce)', false);
  const shouldUsePoster = fallbackImage?.url == null && video?.desktopUrl != null && (noMotion || isSSR);

  if (isSSR && !shouldUsePoster) {
    return null;
  }

  if (video?.desktopUrl != null || shouldUsePoster) {
    if (isPhone) {
      return (
        <Video
          src={video.mobileUrl ?? video.desktopUrl}
          loop
          autoPlay={!noMotion}
          muted
          playsInline
          data-testid="mobile-content-video"
        />
      );
    }

    return (
      <Video src={video.desktopUrl} loop autoPlay={!noMotion} muted playsInline data-testid="desktop-content-video" />
    );
  }

  if (fallbackImage?.url != null && gameName != null) {
    return (
      <FlexibleGameListThumbnail gameName={gameName} thumbnail={fallbackImage.url} loading={loading} sizes="100vw" />
    );
  }

  return null;
};

const useCreateHref = (focusContent: VideoContentHero_data$data['focusContent']) => {
  const { asPath } = useRouter();

  if (focusContent?.slug != null) {
    return focusContent.slug;
  }

  if (focusContent?.launchUrl != null) {
    return focusContent.launchUrl;
  }

  if (focusContent?.programExternalId != null) {
    return `${asPath}?flowType=bonus&programExternalId=${focusContent?.programExternalId}`;
  }

  if (focusContent?.promotionId != null) {
    if (focusContent.promotionSlug != null) {
      return `${asPath}?flowType=promotion&promotionSlug=${focusContent?.promotionSlug}`;
    }

    return `${asPath}?flowType=promotion&promotionId=${focusContent?.promotionId}`;
  }
};

type VideoContentHeroComponentProps = {
  data: VideoContentHero_data$key;
  content: ReactNode;
  loading?: 'eager' | 'lazy';
  onHeroClick: () => void;
};

const VideoContentHero: FC<VideoContentHeroComponentProps> = ({ data, content, loading, onHeroClick }) => {
  const { ctaButtonText, sellingText, video, focusContent } = useFragment(dataFragment, data);
  const { openFlow } = useFlowRouter();
  const href = useCreateHref(focusContent);

  const handleClick = () => {
    if (focusContent?.programExternalId != null) {
      openFlow('bonus', { programExternalId: focusContent.programExternalId });
    }

    if (focusContent?.promotionId != null) {
      if (focusContent.promotionSlug != null) {
        openFlow('promotion', {
          promotionSlug: focusContent.promotionSlug,
          title: focusContent.customPromotionTitle ?? undefined,
        });
      } else {
        openFlow('promotion', {
          promotionId: focusContent.promotionId,
          title: focusContent.customPromotionTitle ?? undefined,
        });
      }
    }

    onHeroClick();
  };

  return (
    <>
      <ContentSection>
        <PictureLink href={href} title={focusContent?.name} onClick={handleClick}>
          <VideoContentHeroPictureMaterial
            video={video}
            fallbackImage={focusContent?.fallbackImage}
            loading={loading}
            gameName={focusContent?.name}
          />
        </PictureLink>
        <Content>
          {focusContent?.gameTags ? (
            <GameTags>
              {focusContent.gameTags.map((tag, index) => (
                <GameTag key={index}>{prettifyHeroTagName(tag)}</GameTag>
              ))}
            </GameTags>
          ) : null}

          {sellingText && <HeroRichTextComponent jsonContent={sellingText.jsonContent} />}

          {ctaButtonText && (
            <HeroCtaButtonLink href={href} size="hero" variant="primary" onClick={handleClick}>
              {ctaButtonText}
            </HeroCtaButtonLink>
          )}
        </Content>
      </ContentSection>
      <HeroContentWrapper>{content}</HeroContentWrapper>
    </>
  );
};

export default VideoContentHero;
